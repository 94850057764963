import React from 'react'
import { render } from 'react-dom'
// import './index.css'
// import BrowserRouter from 'react-router-dom/BrowserRouter'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
// import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http'
// import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
// import { ApolloProvider } from 'react-apollo';
// import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
// import { AuthProvider } from './Context/AuthContext';
import { ApolloClient, ApolloProvider, InMemoryCache, defaultDataIdFromObject } from '@apollo/client'
// const liffId = process.env.REACT_APP_LINE_LIFF_ID
const liffId = '1655111748-Al7JBekk'
// const liffId = '1655111748-5nLrEkyy'
window.liff &&
  window.liff.init(
    { liffId },
    () => {},
    (err) => console.error(err.code, err.message)
  )
// import './index.css'
// const clientId = process.env.REACT_APP_CLIENT_ID
const clientId = window.location.hostname
const platform = process.env.REACT_APP_PLATFORM
const apolloClient = new ApolloClient({
//   // initialState: window.__APOLLO_STATE__,
//   // networkInterface
  link: new BatchHttpLink({
    uri: process.env.REACT_APP_API_URI,
    credentials: 'include',
    headers: {
      ...(clientId && { 'X-ThaiRun-Client-ID': clientId }),
      ...(platform && {
        'X-RunX-Platform': platform
      })
    }
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      if (!['Registration', 'User'].includes(object.__typename)) {
        return null
      }
      // console.log('apolloclient_cache')
      // return null
      // if (
      //   ['PropertyQuestionItems', 'PropertyQuestionCondition'].includes(
      //     object.__typename
      //   )
      // ) {
      //   return null
      // }
      // if (['UserProfile'].includes(object.__typename)) {
      //   return null
      // }
      return defaultDataIdFromObject(object)
    }
  })
})
console.log('version', process.env.REACT_APP_WEBAPP_VERSION)
render(
  // <AuthProvider>
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>,
  // </AuthProvider>,
  document.getElementById('root')
)

// registerServiceWorker()
