import React, { lazy, Suspense } from "react";

const ThemeMap = {
  thai: lazy(() => import("./ThairunTheme")),
  indo: lazy(() => import("./IndonesiaTheme")),
  viet: lazy(() => import("./VietnamTheme")),
  maybank: lazy(() => import("./MaybankTheme.js")),
  mice: lazy(() => import("./MiceTheme.js")),
  ultratopspin: lazy(() => import("./Ultratopspin.js"))
}

const Theme = ThemeMap[process.env.REACT_APP_PLATFORM || 'thai']

export const ThemeProvider = ({ children }) => {

  return (
    <React.Fragment>
      <Suspense fallback={<span />}>
        <Theme />
      </Suspense>
      {children}
    </React.Fragment>
  );
};
