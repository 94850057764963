import { observable, action } from 'mobx'
import { Cookies } from 'react-cookie'

const cookie = new Cookies()

class I18NStore {
  @observable locale = cookie.get('locale') || 'th'
  @action
  setLocale (locale) {
    this.locale = locale
    cookie.set('locale', locale, { path: '/' })
  }
}
const i18NStore = new I18NStore()

export default i18NStore
