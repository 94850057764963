import React, { Component } from 'react'
import { Button, Modal } from 'antd'
import { Footer as FOOTER } from '~/styles'
// import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import './style.css'
// const appVersion = process.env.REACT_APP_WEBAPP_VERSION
@injectIntl
class Footer extends Component {
  hardReload = () => {
    window.location.reload(true)
  }
  footerModal = () => {
    Modal.info({
      title: `version: ${process.env.REACT_APP_WEBAPP_VERSION}`,
      okText: this.props.intl.formatMessage({ id: 'app.back' }),
      maskClosable: true,
      content: (
        <div>
          <div>
            <Button className='hardReload' icon="reload" onClick={this.hardReload} >
              {this.props.intl.formatMessage({id: 'app.refresh'})}
            </Button>
          </div>
        </div>
      ),
      onOk () {}
    })
  }
  render () {
    // console.log(_get(process, 'env.REACT_APP_WEBAPP_VERSION'))
    return (
      <FOOTER>
        <div className="last-row" >
          <span id="copyright" className="last-line">Copyright © 2024</span>
          { process.env.REACT_APP_PLATFORM !== 'mice' &&
          <div id="social" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <a href="/" style={{ height: '35px', width: '70px' }} key="instagram">
              <img src="/ultratopspin/logo.png" alt="logo" style={{width: '100%'}} />
            </a>
            {/* <a href="https://www.youtube.com/channel/UCHVnTje5HIUcmswGyqqHTwg" style={{ height: '35px' }} key="youtube">
              <svg id="youtubeIcon" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="21" height="22">
                  <path d="M11.166 0.916992C16.736 0.916992 21.249 5.42999 21.249 11C21.249 16.57 16.735 21.083 11.166 21.083C9.84182 21.0833 8.53054 20.8226 7.3071 20.316C6.08366 19.8094 4.97201 19.0667 4.03566 18.1303C3.09932 17.194 2.35662 16.0823 1.85 14.8589C1.34337 13.6355 1.08275 12.3242 1.08301 11C1.08301 5.42999 5.59601 0.916992 11.166 0.916992ZM11.166 2.49199C10.0487 2.49199 8.94238 2.71206 7.91014 3.13963C6.8779 3.56719 5.93998 4.19389 5.14994 4.98393C3.55438 6.57949 2.65801 8.74353 2.65801 11C2.65801 13.2565 3.55438 15.4205 5.14994 17.0161C5.93998 17.8061 6.8779 18.4328 7.91014 18.8604C8.94238 19.2879 10.0487 19.508 11.166 19.508C13.4225 19.508 15.5865 18.6116 17.1821 17.0161C18.7776 15.4205 19.674 13.2565 19.674 11C19.674 9.88271 19.4539 8.77636 19.0264 7.74412C18.5988 6.71188 17.9721 5.77397 17.1821 4.98393C16.392 4.19389 15.4541 3.56719 14.4219 3.13963C13.3896 2.71206 12.2833 2.49199 11.166 2.49199ZM11.167 7.33299C12.313 7.33299 13.463 7.33299 14.6 7.47899C15.08 7.54099 15.479 7.95299 15.59 8.51299C15.75 9.30999 15.75 10.18 15.75 11C15.75 11.82 15.749 12.69 15.589 13.487C15.477 14.047 15.078 14.459 14.599 14.521C13.462 14.667 12.311 14.667 11.166 14.667H10.593C9.63701 14.663 8.68001 14.642 7.73301 14.521C7.25301 14.459 6.85401 14.046 6.74301 13.487C6.58301 12.69 6.58301 11.82 6.58301 11C6.58301 10.18 6.58401 9.30999 6.74401 8.51299C6.85601 7.95299 7.25401 7.54099 7.73401 7.47899C8.87101 7.33299 10.022 7.33299 11.167 7.33299ZM9.98301 9.15299V12.633L12.897 10.899L9.98301 9.15399V9.15299Z" fill="white" />
                </mask>
                <g mask="url(#mask0)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.166016 0H22.166V22H0.166016V0Z" fill="white" />
                </g>
              </svg>
            </a>
            <a href="https://www.facebook.com/thaidotrun/" style={{ height: '35px' }} key="facebook">
              <svg id="facebookIcon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                  <path d="M10.083 0C15.653 0 20.166 4.513 20.166 10.083C20.166 15.653 15.652 20.166 10.083 20.166C8.75881 20.1663 7.44754 19.9056 6.22409 19.399C5.00065 18.8924 3.889 18.1497 2.95266 17.2133C2.01631 16.277 1.27361 15.1654 0.766988 13.9419C0.260363 12.7185 -0.000262538 11.4072 1.98451e-07 10.083C1.98451e-07 4.513 4.513 0 10.083 0ZM10.083 1.575C8.96571 1.575 7.85937 1.79507 6.82713 2.22263C5.79489 2.6502 4.85698 3.27689 4.06694 4.06694C2.47138 5.6625 1.575 7.82654 1.575 10.083C1.575 12.3395 2.47138 14.5035 4.06694 16.0991C4.85698 16.8891 5.79489 17.5158 6.82713 17.9434C7.85937 18.3709 8.96571 18.591 10.083 18.591C12.3395 18.591 14.5035 17.6946 16.0991 16.0991C17.6946 14.5035 18.591 12.3395 18.591 10.083C18.591 8.96571 18.3709 7.85937 17.9434 6.82713C17.5158 5.79489 16.8891 4.85698 16.0991 4.06694C15.309 3.27689 14.3711 2.6502 13.3389 2.22263C12.3066 1.79507 11.2003 1.575 10.083 1.575ZM11.152 5.041L12.451 5.048V6.776H11.318C11.128 6.776 10.722 6.866 10.722 7.259V8.539H12.476L12.205 10.319H10.722V15.125H8.976V10.319H7.69V8.539H8.976V7.156C8.976 6.33 9.38 5.042 11.152 5.042V5.041Z" fill="white" />
                </mask>
                <g mask="url(#mask2)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M-0.916992 -0.916992H21.083V21.083H-0.916992V-0.916992Z" fill="white" />
                </g>
              </svg>
            </a> */}
          </div>
          }
          <span id="thairun-co" className="last-line">Member of <img src="/white_logo.png" alt="logo" style={{maxWidth: '70px', width: '100%'}} /></span>
        </div>
        {/* <Row className="bottomRow" type="flex" justify="start" >
          <Col xs={6} md={{ span: 3, offset: 0 }}>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img id="footerLogo" className="footer-logo" src="/logothairun-(W).png" alt="Thai.Run" />
            </Link>
          </Col>
          <Col xs={{ span: 16, offset: 1 }} md={{ span: 6, offset: 1 }} className="contactUsSection">
            <div style={{ lineHeight: 0.9, fontWeight: 'lighter', marginTop: '10px' }}>
              <span className="address">809 โครงการ Stadium One ซอย จุฬาลงกรณ์ 4 ถนน บรรทัดทอง แขวง วังใหม่ เขต ปทุมวัน กรุงเทพฯ 10330</span><br />
              <span className="address">race@thai.run</span>
            </div>
          </Col>
          <Col className="followUsSection" xs={{ span: 24, offset: 7 }} md={{ span: 4, offset: 1 }} >
            <div style={{marginTop: '10px'}}>
              <a href='https://www.facebook.com/thaidotrun/?epa=SEARCH_BOX' target="_blank" alt="Facebook" rel="noopener noreferrer" >
                <img src="/facebook.png" alt="Facebook" style={{ width: '10%', marginRight: '5px' }} className="footer-logo-facebook"/>
              </a>
              <a href='https://m.me/thaidotrun' target="_blank" alt="Facebook" rel="noopener noreferrer" >
                <img src="/messenger.png" alt="messenger" style={{ width: '10%' }} className="footer-logo-facebook"/>
              </a>
            </div>
            <div style={{marginTop: '10px'}}>
              <Link className="privacy-policy-footer" to="/privacy-policy">Privacy Policy</Link>
            </div>
          </Col>
        </Row> */}
      </FOOTER>
    )
    // <FooterContainerV2 style={{display: 'flex', justifyContent: 'flex-start'}}>
    //   <ImgLogoFooterV2>
    //     <img src="/logothairun-(W).png" alt="ImgLogoFooter" style={{ width: '100%', maxWidth: '130px', maxHeight: '70px', marginBottom: 10 }} className="footer-logo"/>
    //   </ImgLogoFooterV2>
    //   <ContactFooterV2>
    //     <h2 style={{ color: '#fff', fontWeight: 'bold', marginBottom: '0.1em' }}>CONTACT US</h2>
    //     <p style={{ color: '#fff' }}>809 โครงการ stadium One ซอย จุฬาลงกรณ์ 4 ถนน บรรทัดทอง แขวง วังใหม่ เขต ปทุมวัน กรุงเทพฯ 10330</p>
    //     <a href='https://www.facebook.com/thaidotrun/?epa=SEARCH_BOX' target="_blank" alt="Facebook" rel="noopener noreferrer" >
    //       <img src="/facebook.png" alt="Facebook" style={{ width: '10%', marginRight: '5px' }} className="footer-logo-facebook"/>
    //     </a>
    //     <a href='https://m.me/thaidotrun' target="_blank" alt="Facebook" rel="noopener noreferrer" >
    //       <img src="/messenger.png" alt="messenger" style={{ width: '10%' }} className="footer-logo-facebook"/>
    //     </a>
    //   </ContactFooterV2>
    // </FooterContainerV2>
  }
}

export default Footer
